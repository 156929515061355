

import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { FaArrowRight } from "react-icons/fa";
import example from "../../assets/image/jpeg/examples.jpg";
 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { device, breakpoints } from "../../utils";
import Newarrow from '../../assets/image/jpeg/enter.svg';




const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;






const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;



const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;

const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;




const Thirdtext = styled.div`

opacity: 1;
transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
0deg) rotateY(
0deg) rotateZ(
0deg) skew(
0deg, 
0deg);
transform-style: preserve-3d;
font-family: halyard-display, sans-serif;

width: 90%;
color: rgba(255, 255, 255, 0.7);


color: #fff;
font-size: 1.6em;
line-height: 1.5;


@media screen and (max-width: 767px)
{
     margin-bottom: 24px;
     font-size: 22px;
     text-align:center;
     width: 100%;
 }

`;
const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;

  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;

const Buttonnew = styled.button `
color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efe8de;
  border: none;
  padding: 1rem 4rem;
  font-weight: 600;
  font-family: termina, sans-serif;
  text-transform: uppercase;
  font-size: 0.7rem;
  position: relative;
  border-radius: 100px;
  z-index: 1;
  margin-top:20px;
  margin-right: 1rem;
  cursor: pointer;
  outline: none;
  transform-style: preserve-3d;
  transition: 0.2s ease-out;

  &:hover {
    &.main span {
      color: #fff;
      mix-blend-mode: difference;
    }
    &.alt span {
      mix-blend-mode: difference;
      color: white;
    }
  }

  &.main {
    background: #00afc9;
    color: #efe8de;
    margin: 0 auto;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: -5px -6px 10px #efe8de;
    filter: brightness(1.2);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: 5px 10px 15px #efe8de;
    filter: brightness(0.8);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &:active {
    &.alt {
      &::after {
        box-shadow: inset 4px 4px 8px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #efe8de;
      }
    }
    &.main {
      &::after {
        box-shadow: 2px 5px 6px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #222;
      }
    }
  }
`

export default function Exofaq() {
    return (
        <Section py={4} id="faq" bg="#ffffff" className="pb-md-5 mb pt-md-5">
       <Container className="pt-2 pb-2"    data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
        <Row className="justify-content-center">
          <Col lg="12">
   
            <Iwrap>


<Itext as="h2">Questions about <span>ExoMeso</span></Itext>
</Iwrap>
      <SecondText>
      Frequently Asked Questions

      </SecondText>
         
          </Col>
        </Row>
<br /><br />

        <Row className="">
            <Col lg="6">
       
        <Col>
        <Accordion allowZeroExpanded classname="">
    <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton>
                What is Exo Meso?
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
            <p>
                Exo Meso is a specialized hair treatment designed to address hair loss, strengthen hair from the roots, and promote healthier scalp conditions through a multi-faceted approach.
            </p>
        </AccordionItemPanel>
    </AccordionItem>
</Accordion><br />

<Accordion allowZeroExpanded classname="">
    <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton>
                How does Exo Meso work to prevent hair loss?
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
            <p>
                Exo Meso targets the hormonal causes of hair loss by blocking the enzyme 5-alpha reductase, which helps prevent hair follicle shrinkage and prolongs the hair life cycle.
            </p>
        </AccordionItemPanel>
    </AccordionItem>
</Accordion><br />

<Accordion allowZeroExpanded classname="">
    <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton>
                Who is Exo Meso suitable for?
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
            <p>
                Exo Meso is suitable for both men and women experiencing hair loss or thinning at any stage of life, making it ideal for those looking to restore hair density and health.
            </p>
        </AccordionItemPanel>
    </AccordionItem>
</Accordion><br />

<Accordion allowZeroExpanded classname="">
    <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton>
                What are the key benefits of Exo Meso?
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
            <p>
                The treatment stops hair loss, promotes new hair growth, strengthens hair roots, reduces scalp inflammation, balances sebum production, and improves scalp blood circulation.
            </p>
        </AccordionItemPanel>
    </AccordionItem>
</Accordion><br />

<Accordion allowZeroExpanded classname="">
    <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton>
                How long does it take to see results?
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
            <p>
                Results can vary, but most people notice improvements in hair strength and reduced shedding within a few weeks, with more visible growth benefits over several months.
            </p>
        </AccordionItemPanel>
    </AccordionItem>
</Accordion><br />

<Accordion allowZeroExpanded classname="">
    <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton>
                Does Exo Meso help with all types of hair loss?
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
            <p>
                Yes, Exo Meso addresses multiple causes of hair loss, including hormonal imbalance, scalp inflammation, sebum buildup, and nutrient deficiency, making it effective for various types of alopecia.
            </p>
        </AccordionItemPanel>
    </AccordionItem>
</Accordion><br />

<Accordion allowZeroExpanded classname="">
    <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton>
                Is Exo Meso a safe treatment?
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
            <p>
                Yes, Exo Meso is a scientifically designed treatment that is safe for use on the scalp and hair, with a focus on promoting natural hair growth and scalp health.
            </p>
        </AccordionItemPanel>
    </AccordionItem>
</Accordion><br />

<Accordion allowZeroExpanded classname="">
    <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton>
                How is Exo Meso applied?
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
            <p>
                Exo Meso is applied directly to the scalp to target hair follicles, where it works to stimulate circulation, balance sebum production, and supply essential nutrients.
            </p>
        </AccordionItemPanel>
    </AccordionItem>
</Accordion><br />

<Accordion allowZeroExpanded classname="">
    <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton>
                Can Exo Meso be used with other hair treatments?
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
            <p>
                Yes, Exo Meso can complement other hair care routines and treatments, but it’s best to consult a professional to ensure compatibility and optimize results.
            </p>
        </AccordionItemPanel>
    </AccordionItem>
</Accordion><br />

<Accordion allowZeroExpanded classname="">
    <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton>
                How often should I use Exo Meso?
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
            <p>
                Usage frequency varies based on individual needs; however, consistent application is key for long-lasting results. A doctor or specialist can provide a tailored plan.
            </p>
        </AccordionItemPanel>
    </AccordionItem>
</Accordion><br />

    </Col>


          </Col>




        </Row>




              <a href 
                                            ="https://connect.pabau.com/bookings.php?compid=8607" 
                                            activeClassName="active"
                                           
                                          
                                        >      <Bookbutton>
                
                
                Book Appointment
                
              
                </Bookbutton> </a>
        


       


        </Container>
        </Section>

 


    );
}

